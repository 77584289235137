import { lazy } from "react";

import { linkResolver } from "../src/utilities/prismic-linkResolver";

export default [{
  repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
  linkResolver,
  componentResolver: {
    collections: lazy(() => import("../src/templates/Collection")),
    homepage: lazy(() => import("../src/templates/Homepage")),
    projects: lazy(() => import("../src/templates/Project")),
    project_collections: lazy(() => import("../src/templates/ProjectCollection")),
    simple_page: lazy(() => import("../src/templates/SimplePage")),
  },
}];
