module.exports = {
  siteUrl: "https://www.ankatechniki.gr/",
  siteLang: "el-gr", // This should be the same as Prismic master locale
  title: "Anka Tehcniki",
  shortTitle: "Anka Techniki",
  description: "Anka Techniki",
  logoUrl: "https://www.ankatechniki.gr/images/logo.jpg",

  // Values used in manifest
  siteBackgroundColor: "#fff",
  siteThemeColor: "#000",
  siteIcon: "./src/images/icon.png",

  // Image Details used as fallback at metadata
  defaultImage: "https://www.ankatechniki.gr/images/logo.jpg",
  defaultImageWidth: "512",
  defaultImageHeight: "512",
  defaultImageAlt: "anka techniki",
};
