exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-collection-js": () => import("./../../../src/templates/Collection.js" /* webpackChunkName: "component---src-templates-collection-js" */),
  "component---src-templates-homepage-js": () => import("./../../../src/templates/Homepage.js" /* webpackChunkName: "component---src-templates-homepage-js" */),
  "component---src-templates-project-collection-js": () => import("./../../../src/templates/ProjectCollection.js" /* webpackChunkName: "component---src-templates-project-collection-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/Project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-simple-page-js": () => import("./../../../src/templates/SimplePage.js" /* webpackChunkName: "component---src-templates-simple-page-js" */)
}

