module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"ankatechniki","accessToken":"MC5aZWhFUFJFQUFDQUEyemRm.77-977-977-977-9Au-_vUYgA--_ve-_ve-_ve-_ve-_ve-_vQpt77-977-9WO-_vTBQ77-9Oe-_vVfvv73vv73vv73vv73vv70"},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"start_url":"/","lang":"el","name":"Anka Tehcniki","short_name":"Anka Techniki","description":"Anka Techniki","background_color":"#fff","theme_color":"#000","display":"minimal-ui","icon":"./src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"09756ccf3ba0edc02c32e440a27e1f8b"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
